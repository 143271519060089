<template>
  <el-dialog
    title="充值"
    :visible="rechargeVisible"
    width="600px"
    @close="closeDialog"
  >
    <el-form
      label-position="top"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
      label-width="80px"
    >
      <el-form-item style="width: 100%" prop="amount" label="充值金额">
        <el-input-number
          v-model="ruleForm.amount"
          placeholder="请输入充值金额"
        ></el-input-number>
      </el-form-item>
      <el-form-item style="width: 100%" prop="remark" label="备注">
        <el-input
          v-model="ruleForm.remark"
          type="textarea"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeDialog()">取 消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { api } from '../../api';
export const FinancialSubAccountRecharge = api('/financialAccountHistory')(
  'financialSubAccount.recharge.json'
);

export default {
  data() {
    return {
      userList: [],
      platformList: [],
      ruleForm: {
        amount: null,
        remark: ''
      },
      rules: {
        amount: [{ required: true, message: '请输入充值金额', trigger: 'blur' }]
      }
    };
  },
  props: {
    rechargeVisible: Boolean,
    code: String
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (this.ruleForm.amount === 0)
            return this.$message.error('充值金额不能为0！');
          await FinancialSubAccountRecharge({
            code: this.code,
            ...this.ruleForm
          });
          this.$message.success('充值成功');
          this.$refs[formName].resetFields();
          this.closeDialog();
        } else {
          return false;
        }
      });
    },
    closeDialog() {
      this.$emit('update:rechargeVisible', false);
      this.$emit('rechargeClose');
    }
  }
};
</script>

<style>
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
.el-dialog__body {
  max-height: 500px;
  overflow: auto;
  padding: 10px 40px;
}
</style>
